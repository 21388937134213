import React from 'react'
// import { FormattedMessage } from 'react-intl'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import * as styles from '../../styles/Apps.module.scss'

//Components
import Layout from '../../components/layout'

// class Apps extends Component {
const Windows = ({ data, pageContext: { locale } }) => {
  const { allDatoCmsApp, allFile } = data

  return (
    <Layout locale={locale}>
      {allDatoCmsApp.edges.map(app => {
        return (
          <div key={app.node.id}>
            <section className={styles.appHeader}>
              <div className={styles.appContainer}>
                <div className={`${styles.item}`}>
                  <GatsbyImage
                    alt=""
                    image={app.node.appImage.gatsbyImageData}
                  />
                </div>
                <div className={`${styles.item} ${styles.positionFlex}`}>
                  <div className={styles.appInfo}>
                    <h2>{app.node.appDownloadTitle}</h2>
                    <a href={app.node.linkApp} className={`btn ${styles.btn}`}>
                      <FormattedMessage id="download" />
                    </a>
                    <div className={styles.appAddInfo}>
                      <Link className="normal" to={`/${locale}/apps/osx/`}>
                        <FormattedMessage id="change_os" />
                      </Link>
                      <div className="up-line" />
                      <a
                        className="normal"
                        rel="noreferrer noopener"
                        href="https://admin.joinflow.com/"
                      >
                        <FormattedMessage id="open_in_browser" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className={styles.mobileSection}>
              <div className={styles.appContainer}>
                <h2>{app.node.appMobile}</h2>
                <div className={styles.mobileContainer}>
                  {allFile.edges.map(file => {
                    return (
                      <div key={file.node.id} className={`${styles.mobileImg}`}>
                        {(() => {
                          if (file.node.name === 'appstore') {
                            return (
                              <a
                                className={styles.stores}
                                href={app.node.appIphone}
                              >
                                <GatsbyImage
                                  alt=""
                                  image={
                                    file.node.childImageSharp.gatsbyImageData
                                  }
                                />
                              </a>
                            )
                          } else {
                            return (
                              <a
                                className={styles.stores}
                                href={app.node.appAndroid}
                              >
                                <GatsbyImage
                                  alt=""
                                  image={
                                    file.node.childImageSharp.gatsbyImageData
                                  }
                                />
                              </a>
                            )
                          }
                        })()}
                      </div>
                    )
                  })}
                </div>
              </div>
            </section>
            <section className={styles.desktopSection}>
              <div
                className={`${styles.desktopContainer} ${styles.appContainer}`}
              >
                <div className={`${styles.item}`}>
                  <h3>{app.node.operator}</h3>
                  <div className={styles.downloadInfo}>
                    <a href={app.node.linkOperator} className="btn-border">
                      <FormattedMessage id="download" />
                    </a>
                  </div>
                </div>
                <div className={`${styles.item}`}>
                  <h3>{app.node.desktopUc}</h3>
                  <div className={styles.downloadInfo}>
                    <a href={app.node.linkDesktopUc} className="btn-border">
                      <FormattedMessage id="download" />
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )
      })}
    </Layout>
  )
}

export default Windows

export const query = graphql`
  query windowsQuery($locale: String!) {
    allDatoCmsApp(
      filter: { locale: { eq: $locale }, path: { eq: "windows" } }
    ) {
      edges {
        node {
          id
          locale
          appTitle
          linkApp
          appDownloadTitle
          appIphone
          appAndroid
          operator
          desktopUc
          appMobile
          path
          linkOperator
          linkDesktopUc
          appImage {
            url
            gatsbyImageData(width: 600, placeholder: BLURRED)
          }
        }
      }
    }

    allFile(filter: { relativePath: { regex: "/icons/" } }) {
      edges {
        node {
          id
          sourceInstanceName
          relativePath
          name
          childImageSharp {
            gatsbyImageData(width: 189, height: 56, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
